import React from 'react';
import Button from "../Button";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';

const ValuePropBlog = props => {

  return (
    <section className="value-prop-blog">
      <div className="value-prop-content-blog">
        <div className="main-message-blog">
          <p className="main-abovetitle-blog"><Link to="/" className="main-abovetitle-a-propos">Accueil</Link> &#62; Blog</p>
          <h1>Blog</h1>
          <p className="main-subtitle-blog">Vous retrouverez ici tous nos articles.</p>
        </div>
      </div>
    </section>
  )
};

export default ValuePropBlog;