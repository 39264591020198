import React from "react";

import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import ValuePropBlog from "../../components/blog/ValuePropBlog";
import ProductBenefitsPortfolio from "../../components/portfolio/ProductBenefitsPortfolio";
import { graphql, Link } from "gatsby";
import ArticleCard from "../../components/blog/ArticleCard";
import Img from "gatsby-image";

export default function BlogPage  ({data}) {
  console.log(data);
  const articles = data.allMarkdownRemark.nodes
  return (
    <Layout>
      <SEO title="Développement d'applications web et mobiles" />
      <ValuePropBlog />
      <div className="articles-list-content">
        {articles.map(article =>(
          <div key={article.id} className="card-content">
            <ArticleCard 
              title={article.frontmatter.title} 
              slug={article.frontmatter.slug} 
              tags={article.frontmatter.tags}
              image={article.frontmatter.thumb.childImageSharp.fluid}
            />
          </div>
        ))}
      </div>
    </Layout>
  );
}

//export page query 
export const query = graphql`
query ArticlesPages {
  allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}) {
    nodes {
      frontmatter {
        title
        tags
        date
        slug
        thumb {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}

`