import React from "react"
import Button from "../Button"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ArticleCard = props => {
  return (
    <div className="card-content">
      
    <Link to={"/articles/" + props.slug} className="card-link">
      <article class="blog-card">
          <Img fluid={props.image} className="post-image" />
          <div class="article-details">
            <h4 className="post-category">{props.tags}</h4>
            <h3 className="post-title">{props.title}</h3>
          </div>
      </article>
        </Link>
    </div>
  )
}

export default ArticleCard
